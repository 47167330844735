<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <Form :form="form" route="medical-checkup" type="add" title="Tambah Medical Check Up"/>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Form from "@/component/medical-checkup/Form.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";

export default {
  components: {
    Card,
    Form,
  },

  data() {
    return {
      // Form
      form: {
        // primary data
        patient_id: "",
        doctor_id: null,
        no_patient: "",
        mcu_time: moment().format("YYYY-MM-DD"),
        mcu_number: moment().format("YYYYMMDD")+Math.floor(Math.random() * (999 - 100 + 1)),
        patient_name: "",
        weight: "",
        height: "",
        bmi: "",
        temperature: "",
        blood_pressure: "",
        pulse: "",
        respiratory_rate: "",
        health_history: {
          head_injury: 0,
          lost_consciousness: 0,
          tht_distrubance: 0,
          sexual_distrubance: 0,
          skin_distrubance: 0,
          diarrhea: 0,
          grastistis: 0,
          heart_infection: 0,
          hemoroid: 0,
          diabetes: 0,
          tuberculosis: 0,
          mental_distrubance: 0,
          sexual_infection: 0,
          weight_lose: 0,
          high_blood_pressure: 0,
          heart_disease: 0,
          surgery_expirience: 0,
          fever_typoid: 0,
          pain_at_joint: 0,
          urinary_tract_disorders: 0,
          asma_bronkial: 0,
          food_alergy: 0,
          vertigo: 0,
        },
        family_health_history: {
          father: 0,
          mother: 0,
          siblings: 0,
        },
        working_history: {
          noise_vibration: 0,
          temperature_over: 0,
          smoke_dust: 0,
          chemical: 0,
          big_machine: 0,
        },
        habits: {
          smoking: 0,
          drunk: 0,
          drugs: 0,
        },
        head: {
          hair: 0,
        },
        eyes: {
          visus_right: 0,
          visus_left: 0,
          is_glasses: 0,
          strabismus: 0,
          steve_conjungtiva: 0,
          // kesan: 0,
          color_blind: 0,
          pupillary_reflex: 0,
        },
        ears: {
          ear_shape: 0,
          tympani_membran: 0,
          meatus_acusticus: 0,
        },
        nose: {
          nose_shape: 0,
          smelling: 0,
          mucosa_nose: 0,
          epistaksis: 0,
        },
        throat: {
          tonsil_faring: 0,
          laring: 0,
        },
        mounth_teeth: {
          mukosa_mounth: 0,
          tongue: 0,
          teeth: 0,
        },
        neck: {
          lympe_thyroid: 0,
          jagular_venous_pressure: 0,
        },
        thorax: {
          thorax_shape: 0,
          thorax_moving: 0,
          lungs: 0,
          heart_thorax: 0,
          backbone: 0,
        },
        abdomen: {
          usus_wall: 0,
          genitalia: 0,
          liver_and_spleen: 0,
        },
        extremitas: {
          extremitas_top: 0,
          extremitas_bottom: 0,
        },
        reflex: {
          physioligy: 0,
          phatology: 0,
        },
        coordination: {
          teremor: 0,
          tonus: 0,
          paresis: 0,
          paralysis: 0,
        },
        lab: "",
        radiology: "",
        spirometry: "",
        ekg: "",
        audiometri: "",
        notes: "",
        summary: "",
        suggestion: "",
      },
    };
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Medical Check Up" },
      { title: "Tambah MCU" },
    ]);
  },
};
</script>

<style></style>
