<template>
  <v-app>
    <div class="row">
      <div class="col-md-12">
        <b-form
          @submit.stop.prevent="formOnSubmit"
          enctype="multipart/form-data"
        >
          <div class="my-2">
            <b-card :title="title">
              <div class="row">
                <div class="col-md-6">
                  <!-- Date -->
                  <b-form-group
                    id="input-group-birth-date"
                    label="Tanggal :"
                    label-for="input-sip-birth-date"
                  >
                    <b-input-group>
                      <div class="row">
                        <div class="col-md-12">
                          <v-dialog
                            ref="dialog"
                            v-model="modal2"
                            :return-value.sync="form.mcu_time"
                            persistent
                            width="350px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <b-input-group>
                                <b-input-group-prepend>
                                  <button
                                    class="btn btn-secondary"
                                    type="button"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <i class="fas fa-calendar"></i>
                                  </button>
                                </b-input-group-prepend>
                                <b-form-input
                                  id="input-trigger-modal-patient"
                                  v-model="form.mcu_time"
                                  placeholder="(YYYY-MM-DD)"
                                  @input="formaterDate"
                                >
                                </b-form-input>
                              </b-input-group>
                            </template>

                            <v-date-picker
                              v-if="modal2"
                              v-model="form.mcu_time"
                              locale="id"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="modal2 = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(form.mcu_time)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </div>
                      </div>
                    </b-input-group>

                    <small class="text-danger">{{ error.mcu_time }}</small>
                  </b-form-group>
                  <!-- End Date -->
                </div>

                <!-- <div class="col-md-2">
                  <b-form-group
                    id="input-group-time"
                    label="Waktu:"
                    label-for="input-time"
                  >
                    <div class="row">
                      <div class="col">
                        <b-form-input
                          id="input-hour"
                          v-model="time.hour"
                          placeholder="Jam(HH)"
                        >
                        </b-form-input>
                      </div>
                      <div class="col">
                        <b-form-input
                          id="input-minute"
                          v-model="time.minute"
                          placeholder="Menit(MM)"
                        >
                        </b-form-input>
                      </div>
                    </div>
                    <small class="text-danger">{{ error.time }}</small>
                  </b-form-group>
                </div> -->

                <div class="col-md-6">
                  <b-form-group id="input-group-mcu-no" class="mb-0">
                    <label for="input-operator"
                      >Nomor MCU: <em class="text-muted">opsional</em></label
                    >
                    <b-form-input
                      id="input-mcu-no"
                      v-model="form.mcu_number"
                      placeholder="Nomor Medical Check Up"
                    >
                    </b-form-input>
                    <small class="text-secondary"
                      >Jika dikosongkan akan otomatis menggunakan nomor yang
                      digenerate sistem</small
                    >
                    <small class="text-danger">{{ error.mcu_number }}</small>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <!-- Input Modal Trigger Patient-->
                  <b-form-group
                    id="input-group-trigger-modal-patient"
                    label="Pasien:"
                    label-for="input-trigger-modal-patient"
                  >
                    <b-form-input
                      id="input-trigger-modal-patient"
                      v-model="display.patient_name"
                      placeholder="Pilih Pasien"
                      readonly
                      @click="$bvModal.show('modal-patient')"
                    >
                    </b-form-input>
                    <small class="text-danger">{{ error.patient_id }}</small>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <!-- Doctor-->
                  <b-form-group id="input-group-doctor">
                    <label for="input-doctor">Dokter : </label>
                    <treeselect
                      v-model="form.doctor_id"
                      placeholder="Pilih Dokter"
                      :options="doctors"
                    />
                    <small class="text-danger">{{ error.doctor_id }}</small>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>

          <div class="row">
            <div class="col-md-12 px-4 mt-4">
              <h3>Riwayat Penyakit</h3>
            </div>
          </div>

          <!-- health history -->
          <div class="my-2">
            <b-card title="Riwayat Kesehatan">
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(value, name, index) in form.health_history"
                  :key="index"
                >
                  <b-form-group :id="'input-group-' + name" class="mb-0">
                    <label :for="'input-' + name"
                      >{{ health_history_label[name] }}:
                    </label>
                    <b-form-select
                      :id="'input-' + name"
                      v-model="form.health_history[name]"
                      :options="selectMethod"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    id="input-group-notes-health-history"
                    class="mb-0"
                  >
                    <label for="input-notes-health-history"
                      >Catatan/Lainnya:
                    </label>
                    <b-form-textarea
                      id="input-notes-health-history"
                      v-model="notes.health_history_notes"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end health history -->

          <!-- family history-->
          <div class="my-2">
            <b-card title="Riwayat Kesehatan Keluarga">
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(value, name, index) in form.family_health_history"
                  :key="index"
                >
                  <b-form-group :id="'input-group-' + name" class="mb-0">
                    <label :for="'input-' + name"
                      >{{ family_health_history_label[name] }}:
                    </label>
                    <b-form-select
                      :id="'input-' + name"
                      v-model="form.family_health_history[name]"
                      :options="selectMethod"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    id="input-group-notes-family-health-history"
                    class="mb-0"
                  >
                    <label for="input-notes-family-health-history"
                      >Catatan/Lainnya: <em class="text-muted">opsional</em>
                    </label>
                    <b-form-textarea
                      id="input-notes-family-health-history"
                      v-model="notes.family_health_history_notes"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end family history-->

          <!-- working hisstory-->
          <div class="my-2">
            <b-card title="Riwayat Pekerjaan">
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(value, name, index) in form.working_history"
                  :key="index"
                >
                  <b-form-group :id="'input-group-' + name" class="mb-0">
                    <label :for="'input-' + name"
                      >{{ working_history_label[name] }}:
                    </label>
                    <b-form-select
                      :id="'input-' + name"
                      v-model="form.working_history[name]"
                      :options="selectMethod"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    id="input-group-notes-family-health-history"
                    class="mb-0"
                  >
                    <label for="input-notes-family-health-history"
                      >Catatan/Lainnya: <em class="text-muted">opsional</em>
                    </label>
                    <b-form-textarea
                      id="input-notes-family-health-history"
                      v-model="notes.working_history_notes"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end working hisstory-->

          <!-- habit-->
          <div class="my-2">
            <b-card title="Riwayat Kebiasaan">
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(value, name, index) in form.habits"
                  :key="index"
                >
                  <b-form-group :id="'input-group-' + name" class="mb-0">
                    <label :for="'input-' + name"
                      >{{ habits_label[name] }}:
                    </label>
                    <b-form-select
                      :id="'input-' + name"
                      v-model="form.habits[name]"
                      :options="selectMethod"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    id="input-group-notes-family-health-history"
                    class="mb-0"
                  >
                    <label for="input-notes-family-health-history"
                      >Catatan/Lainnya: <em class="text-muted">opsional</em>
                    </label>
                    <b-form-textarea
                      id="input-notes-family-health-history"
                      v-model="notes.habits_notes"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end habit-->

          <div class="row">
            <div class="col-md-12 px-4 mt-4">
              <h3>Pemeriksaan Fisik</h3>
            </div>
          </div>

          <!-- physical condition -->
          <div class="my-2">
            <b-card title="Status Fisik">
              <div class="row">
                <div class="col-md-3">
                  <b-form-group id="input-group-weight" class="mb-0">
                    <label for="input-weight">Berat Badan: </label>

                    <b-input-group append="Kg">
                      <b-form-input
                        id="input-weight"
                        v-model="form.weight"
                        placeholder="Berat Badan"
                        @input="setBmi"
                      >
                      </b-form-input>
                    </b-input-group>
                    <small class="text-danger">{{ error.weight }}</small>
                  </b-form-group>
                </div>

                <div class="col-md-3">
                  <b-form-group id="input-group-height" class="mb-0">
                    <label for="input-height">Tinggi Badan: </label>

                    <b-input-group append="Cm">
                      <b-form-input
                        id="input-height"
                        v-model="form.height"
                        placeholder="Tinggi Badan"
                        @input="setBmi"
                      >
                      </b-form-input>
                    </b-input-group>
                    <small class="text-danger">{{ error.height }}</small>
                  </b-form-group>
                </div>

                <div class="col-md-3">
                  <b-form-group id="input-group-bmi" class="mb-0">
                    <label for="input-bmi">Index Massa Tubuh (BMI): </label>
                    <b-form-input
                      id="input-bmi"
                      v-model="form.bmi"
                      readonly
                    >
                    </b-form-input>
                  </b-form-group>
                </div>

                <div class="col-md-3">
                  <b-form-group id="input-group-temperature" class="mb-0">
                    <label for="input-temperature">Suhu: </label>

                    <b-input-group append="C">
                      <b-form-input
                        id="input-temperature"
                        v-model="form.temperature"
                        placeholder="Suhu"
                      >
                      </b-form-input>
                    </b-input-group>
                    <small class="text-danger">{{ error.temperature }}</small>
                  </b-form-group>
                </div>

                <div class="col-md-3">
                  <b-form-group id="input-group-blood_pressure" class="mb-0">
                    <label for="input-blood_pressure">Tekanan Darah: </label>

                    <b-input-group append="mmHg">
                      <b-form-input
                        id="input-blood_pressure"
                        v-model="form.blood_pressure"
                        placeholder="Tekanan Darah"
                      >
                      </b-form-input>
                    </b-input-group>
                    <small class="text-danger">{{
                      error.blood_pressure
                    }}</small>
                  </b-form-group>
                </div>

                <div class="col-md-3">
                  <b-form-group id="input-group-pulse" class="mb-0">
                    <label for="input-pulse">Denyut Nadi: </label>

                    <b-input-group append="x/Menit">
                      <b-form-input
                        id="input-pulse"
                        v-model="form.pulse"
                        placeholder="Denyut Nadi"
                      >
                      </b-form-input>
                    </b-input-group>
                    <small class="text-danger">{{ error.pulse }}</small>
                  </b-form-group>
                </div>

                <div class="col-md-3">
                  <b-form-group id="input-group-respiratory_rate" class="mb-0">
                    <label for="input-respiratory_rate"
                      >Frekuensi Napas:
                    </label>

                    <b-input-group append="x/Menit">
                      <b-form-input
                        id="input-respiratory_rate"
                        v-model="form.respiratory_rate"
                        placeholder="Frekuensi Napas"
                      >
                      </b-form-input>
                    </b-input-group>
                    <small class="text-danger">{{
                      error.respiratory_rate
                    }}</small>
                  </b-form-group>
                </div>

                <div class="col-md-12">
                  <b-form-group
                    id="input-group-notes"
                    class="mb-0"
                  >
                    <label for="input-notes"
                      >Catatan/Lainnya:
                    </label>
                    <b-form-textarea
                      id="input-notes-physical_status"
                      v-model="notes.physical_status_notes"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- physical condition -->

          <!-- head-->
          <div class="my-2">
            <b-card title="Kepala">
              <div class="row">
                <div class="col-md-3">
                  <b-form-group id="input-group-head" class="mb-0">
                    <label for="input-head">Rambut: </label>
                    <b-form-select
                      id="input-head"
                      v-model="form.head.hair"
                      :options="selectCondition"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    id="input-group-notes-family-health-history"
                    class="mb-0"
                  >
                    <label for="input-notes-family-health-history"
                      >Catatan/Lainnya: <em class="text-muted">opsional</em>
                    </label>
                    <b-form-textarea
                      id="input-notes-family-health-history"
                      v-model="notes.head_notes"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end head-->

          <!-- eyes-->
          <div class="my-2">
            <b-card title="Mata">
              <div class="row">
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group id="input-group-visus-right" class="mb-0">
                        <label for="input-head">Visus Kanan: </label>
                        <b-form-input
                          id="input-visus-right"
                          v-model="form.eyes.visus_right"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group id="input-group-visus-left" class="mb-0">
                        <label for="input-head">Visus Kiri: </label>
                        <b-form-input
                          id="input-visus-left"
                          v-model="form.eyes.visus_left"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <b-form-group id="input-group-is-glasses" class="mb-0">
                    <label for="input-is-glasses">Menggunakan Kacamata: </label>
                    <b-form-select
                      id="input-is-glasses"
                      v-model="form.eyes.is_glasses"
                      :options="selectMethod"
                    ></b-form-select>
                  </b-form-group>
                </div>

                <div class="col-md-3">
                  <b-form-group id="input-group-strabismus" class="mb-0">
                    <label for="input-strabismus">Strabismus: </label>
                    <b-form-select
                      id="input-strabismus"
                      v-model="form.eyes.strabismus"
                      :options="selectCondition"
                    ></b-form-select>
                  </b-form-group>
                </div>

                <div class="col-md-3">
                  <b-form-group id="input-group-steve-conjungtiva" class="mb-0">
                    <label for="input-steve-conjungtiva"
                      >Sklera & Conjungtiva:
                    </label>
                    <b-form-select
                      id="input-steve-conjungtiva"
                      v-model="form.eyes.steve_conjungtiva"
                      :options="selectCondition"
                    ></b-form-select>
                  </b-form-group>
                </div>

                <div class="col-md-3">
                  <b-form-group id="input-group-color-blind" class="mb-0">
                    <label for="input-color-blind">Buta Warna: </label>
                    <b-form-select
                      id="input-color-blind"
                      v-model="form.eyes.color_blind"
                      :options="selectMethod"
                    ></b-form-select>
                  </b-form-group>
                </div>

                <div class="col-md-3">
                  <b-form-group id="input-group-pupillary-reflex" class="mb-0">
                    <label for="input-pupillary-reflex">Reflex Pupil: </label>
                    <b-form-select
                      id="input-pupillary-reflex"
                      v-model="form.eyes.pupillary_reflex"
                      :options="selectCondition"
                    ></b-form-select>
                  </b-form-group>
                </div>

                <div class="col-md-12">
                  <b-form-group id="input-group-notes-eyes" class="mb-0">
                    <label for="input-notes-eyes"
                      >Catatan/Lainnya: <em class="text-muted">opsional</em>
                    </label>
                    <b-form-textarea
                      id="input-notes-eyes"
                      v-model="notes.eyes_notes"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end eyes-->

          <!-- ears-->
          <div class="my-2">
            <b-card title="Telinga">
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(value, name, index) in form.ears"
                  :key="index"
                >
                  <b-form-group :id="'input-group-' + name" class="mb-0">
                    <label :for="'input-' + name"
                      >{{ ears_label[name] }}:
                    </label>
                    <b-form-select
                      :id="'input-' + name"
                      v-model="form.ears[name]"
                      :options="selectCondition"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group id="input-group-notes-ears" class="mb-0">
                    <label for="input-notes-ears"
                      >Catatan/Lainnya: <em class="text-muted">opsional</em>
                    </label>
                    <b-form-textarea
                      id="input-notes-ears"
                      v-model="notes.ears_notes"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end ears-->

          <!-- nose-->
          <div class="my-2">
            <b-card title="Hidung">
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(value, name, index) in form.nose"
                  :key="index"
                >
                  <b-form-group :id="'input-group-' + name" class="mb-0">
                    <label :for="'input-' + name"
                      >{{ nose_label[name] }}:
                    </label>
                    <b-form-select
                      :id="'input-' + name"
                      v-model="form.nose[name]"
                      :options="selectCondition"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group id="input-group-notes-nose" class="mb-0">
                    <label for="input-notes-nose"
                      >Catatan/Lainnya: <em class="text-muted">opsional</em>
                    </label>
                    <b-form-textarea
                      id="input-notes-nose"
                      v-model="notes.nose_notes"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end ears-->

          <!-- nose-->
          <div class="my-2">
            <b-card title="Hidung">
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(value, name, index) in form.nose"
                  :key="index"
                >
                  <b-form-group :id="'input-group-' + name" class="mb-0">
                    <label :for="'input-' + name"
                      >{{ nose_label[name] }}:
                    </label>
                    <b-form-select
                      :id="'input-' + name"
                      v-model="form.nose[name]"
                      :options="selectCondition"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group id="input-group-notes-nose" class="mb-0">
                    <label for="input-notes-nose"
                      >Catatan/Lainnya: <em class="text-muted">opsional</em>
                    </label>
                    <b-form-textarea
                      id="input-notes-nose"
                      v-model="notes.nose_notes"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end nose-->

          <!-- throat-->
          <div class="my-2">
            <b-card title="Tenggorokan">
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(value, name, index) in form.throat"
                  :key="index"
                >
                  <b-form-group :id="'input-group-' + name" class="mb-0">
                    <label :for="'input-' + name"
                      >{{ throat_label[name] }}:
                    </label>
                    <b-form-select
                      :id="'input-' + name"
                      v-model="form.throat[name]"
                      :options="selectCondition"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group id="input-group-notes-throat" class="mb-0">
                    <label for="input-notes-throat"
                      >Catatan/Lainnya: <em class="text-muted">opsional</em>
                    </label>
                    <b-form-textarea
                      id="input-notes-throat"
                      v-model="notes.throat"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end throat-->

          <!-- mounth_teeth-->
          <div class="my-2">
            <b-card title="Mulut & Gigi">
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(value, name, index) in form.mounth_teeth"
                  :key="index"
                >
                  <b-form-group :id="'input-group-' + name" class="mb-0">
                    <label :for="'input-' + name"
                      >{{ mounth_teeth_label[name] }}:
                    </label>
                    <b-form-select
                      :id="'input-' + name"
                      v-model="form.mounth_teeth[name]"
                      :options="selectCondition"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    id="input-group-notes-mounth_teeth"
                    class="mb-0"
                  >
                    <label for="input-notes-mounth_teeth"
                      >Catatan/Lainnya: <em class="text-muted">opsional</em>
                    </label>
                    <b-form-textarea
                      id="input-notes-mounth_teeth"
                      v-model="notes.mounth_teeth"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end mounth_teeth-->

          <!-- neck-->
          <div class="my-2">
            <b-card title="Leher">
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(value, name, index) in form.neck"
                  :key="index"
                >
                  <b-form-group :id="'input-group-' + name" class="mb-0">
                    <label :for="'input-' + name"
                      >{{ neck_label[name] }}:
                    </label>
                    <b-form-select
                      :id="'input-' + name"
                      v-model="form.neck[name]"
                      :options="selectCondition"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group id="input-group-notes-neck" class="mb-0">
                    <label for="input-notes-neck"
                      >Catatan/Lainnya: <em class="text-muted">opsional</em>
                    </label>
                    <b-form-textarea
                      id="input-notes-neck"
                      v-model="notes.neck"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end neck-->

          <!-- thorax-->
          <div class="my-2">
            <b-card title="Thorax">
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(value, name, index) in form.thorax"
                  :key="index"
                >
                  <b-form-group :id="'input-group-' + name" class="mb-0">
                    <label :for="'input-' + name"
                      >{{ thorax_label[name] }}:
                    </label>
                    <b-form-select
                      :id="'input-' + name"
                      v-model="form.thorax[name]"
                      :options="selectCondition"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group id="input-group-notes-thorax" class="mb-0">
                    <label for="input-notes-thorax"
                      >Catatan/Lainnya: <em class="text-muted">opsional</em>
                    </label>
                    <b-form-textarea
                      id="input-notes-thorax"
                      v-model="notes.thorax"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end thorax-->

          <!-- abdomen-->
          <div class="my-2">
            <b-card title="Abdomen">
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(value, name, index) in form.abdomen"
                  :key="index"
                >
                  <b-form-group :id="'input-group-' + name" class="mb-0">
                    <label :for="'input-' + name"
                      >{{ abdomen_label[name] }}:
                    </label>
                    <b-form-select
                      :id="'input-' + name"
                      v-model="form.abdomen[name]"
                      :options="selectCondition"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group id="input-group-notes-abdomen" class="mb-0">
                    <label for="input-notes-abdomen"
                      >Catatan/Lainnya: <em class="text-muted">opsional</em>
                    </label>
                    <b-form-textarea
                      id="input-notes-abdomen"
                      v-model="notes.abdomen"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end abdomen-->

          <!-- extremitas-->
          <div class="my-2">
            <b-card title="Extremitas">
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(value, name, index) in form.extremitas"
                  :key="index"
                >
                  <b-form-group :id="'input-group-' + name" class="mb-0">
                    <label :for="'input-' + name"
                      >{{ extremitas_label[name] }}:
                    </label>
                    <b-form-select
                      :id="'input-' + name"
                      v-model="form.extremitas[name]"
                      :options="selectCondition"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group id="input-group-notes-extremitas" class="mb-0">
                    <label for="input-notes-extremitas"
                      >Catatan/Lainnya: <em class="text-muted">opsional</em>
                    </label>
                    <b-form-textarea
                      id="input-notes-extremitas"
                      v-model="notes.extremitas"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end extremitas-->

          <!-- reflex-->
          <div class="my-2">
            <b-card title="Reflex">
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(value, name, index) in form.reflex"
                  :key="index"
                >
                  <b-form-group :id="'input-group-' + name" class="mb-0">
                    <label :for="'input-' + name"
                      >{{ reflex_label[name] }}:
                    </label>
                    <b-form-select
                      :id="'input-' + name"
                      v-model="form.reflex[name]"
                      :options="selectCondition"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group id="input-group-notes-reflex" class="mb-0">
                    <label for="input-notes-reflex"
                      >Catatan/Lainnya: <em class="text-muted">opsional</em>
                    </label>
                    <b-form-textarea
                      id="input-notes-reflex"
                      v-model="notes.reflex"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end reflex-->

          <!-- coordination-->
          <div class="my-2">
            <b-card title="Koordinasi Otot">
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(value, name, index) in form.coordination"
                  :key="index"
                >
                  <b-form-group :id="'input-group-' + name" class="mb-0">
                    <label :for="'input-' + name"
                      >{{ coordination_label[name] }}:
                    </label>
                    <b-form-select
                      :id="'input-' + name"
                      v-model="form.coordination[name]"
                      :options="selectCondition"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    id="input-group-notes-coordination"
                    class="mb-0"
                  >
                    <label for="input-notes-coordination"
                      >Catatan/Lainnya: <em class="text-muted">opsional</em>
                    </label>
                    <b-form-textarea
                      id="input-notes-coordination"
                      v-model="notes.coordination"
                      placeholder="Catatan/Lainnya..."
                      rows="2"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end coordination-->

          <div class="row">
            <div class="col-md-12 px-4 mt-4">
              <h3>Kesimpulan</h3>
            </div>
          </div>

          <!-- summary -->
          <div class="my-2">
            <b-card>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-lab"
                   
                  >
                  <label 
                      >Pemeriksaan Laboratorium: <em class="text-muted">opsional</em></label
                    >
                    <div class="document-editor">
                      <ckeditor
                        :editor="editor"
                        @ready="onReady"
                        v-model="form.lab"
                      ></ckeditor>
                    </div>
                    <small class="text-danger">{{ error.lab }}</small>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group
                    id="input-group-radiology"
                    
                  >
                  <label 
                      >Pemeriksaan Radiologi: <em class="text-muted">opsional</em></label
                    >
                    <div class="document-editor">
                      <ckeditor
                        :editor="editor"
                        @ready="onReady"
                        v-model="form.radiology"
                      ></ckeditor>
                    </div>
                    <small class="text-danger">{{ error.radiology }}</small>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group
                    id="input-group-ekg"
                   
                  >
                  <label 
                      >Pemeriksaan EKG: <em class="text-muted">opsional</em></label
                    >
                    <div class="document-editor">
                      <ckeditor
                        :editor="editor"
                        @ready="onReady"
                        v-model="form.ekg"
                      ></ckeditor>
                    </div>
                    <small class="text-danger">{{ error.ekg }}</small>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group
                    id="input-group-spirometry"
                    
                  >
                  <label 
                      >Pemeriksaan Spirometri: <em class="text-muted">opsional</em></label
                    >
                    <div class="document-editor">
                      <ckeditor
                        :editor="editor"
                        @ready="onReady"
                        v-model="form.spirometry"
                      ></ckeditor>
                    </div>
                    <small class="text-danger">{{ error.spirometry }}</small>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group
                    id="input-group-audiometri"
                    
                  >
                  <label 
                      >Pemeriksaan Audiometri: <em class="text-muted">opsional</em></label
                    >
                    <div class="document-editor">
                      <ckeditor
                        :editor="editor"
                        @ready="onReady"
                        v-model="form.audiometri"
                      ></ckeditor>
                    </div>
                    <small class="text-danger">{{ error.audiometri }}</small>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group
                    id="input-group-notes"
                    
                  >
                  <label 
                      >Catatan: <em class="text-muted">opsional</em></label
                    >
                    <div class="document-editor">
                      <ckeditor
                        :editor="editor"
                        @ready="onReady"
                        v-model="form.notes"
                      ></ckeditor>
                    </div>
                    <small class="text-danger">{{ error.notes }}</small>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group
                    id="input-group-summary"
                    
                  >
                  <label 
                      >Kesimpulan: <em class="text-muted">opsional</em></label
                    >
                    <div class="document-editor">
                      <ckeditor
                        :editor="editor"
                        @ready="onReady"
                        v-model="form.summary"
                      ></ckeditor>
                    </div>
                    <small class="text-danger">{{ error.summary }}</small>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group
                    id="input-group-suggestion"
                    
                  >
                   <label 
                      >Saran: <em class="text-muted">opsional</em></label
                    >
                    <div class="document-editor">
                      <ckeditor
                        :editor="editor"
                        @ready="onReady"
                        v-model="form.suggestion"
                      ></ckeditor>
                    </div>
                    <small class="text-danger">{{ error.suggestion }}</small>
                  </b-form-group>
                </div>
              </div>
            </b-card>
          </div>
          <!-- end summary -->

          <!-- Button -->
          <div class="my-2">
            <b-card>
              <b-button type="submit" variant="primary">Simpan</b-button>
              <b-button class="ml-2" variant="default" @click="cancle">
                Batal
              </b-button>
            </b-card>
          </div>
        </b-form>
      </div>

      <!-- modal patient -->
      <ModalPatient @chosenPatient="setPatient" @submitedPatient="setPatient" />
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalPatient from "@/component/general/ModalPatient.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import moment from "moment";

export default {
  props: {
    type: String,
    form: Object,
    route: String,
    title: String,
  },

  components: {
    ModalPatient,
  },

  data() {
    return {
      modal2: false,
      // Display Data
      display: {
        patient_name: "",
      },
      // time: {
      //   hour: moment().format("HH"),
      //   minute: moment().format("mm"),
      // },
      doctors: [],
      // Error
      error: {
        patient_id: "",
        doctor_id: "",
        no_patient: "",
        mcu_time: "",
        mcu_number: "",
        patient_name: "",
        lab: "",
        radiology: "",
        spirometry: "",
        ekg: "",
        audiometri: "",
        notes: "",
        summary: "",
        suggestion: "",
        weight: "",
        height: "",
        bmi: "",
        temperature: "",
        blood_pressure: "",
        pulse: "",
        respiratory_rate: "",
      },
      // lable
      health_history_label: {
        head_injury: "Cedera Kepala",
        lost_consciousness: "Hilang Kesadaran",
        tht_distrubance: "Gangguan THT > 4mg",
        sexual_distrubance: "Gangguan Alat Reproduksi",
        skin_distrubance: "Penyakit Kulit",
        diarrhea: "Diare Kronis",
        grastistis: "Maag (Grastistis)",
        heart_infection: "Infeksi Hati",
        hemoroid: "Hemoroid (Wasir)",
        diabetes: "Diabetes",
        tuberculosis: "Tuberculosis (TBC)",
        mental_distrubance: "Gangguan Kejiwaan",
        sexual_infection: "Penyakit Sexual",
        weight_lose: "Perubahan Berat Badan > 5kg",
        high_blood_pressure: "Tekanan Darah Tinggi",
        heart_disease: "Sakit Jantung",
        surgery_expirience: "Pernah Dioperasi",
        fever_typoid: "Demam Typoid",
        pain_at_joint: "Nyeri Pada Sendi",
        urinary_tract_disorders: "Kelainan Saluran Kencing",
        asma_bronkial: "Asma Bronkial",
        food_alergy: "Alergi Makanan",
        vertigo: "Pusing Berputar (vertigo)",
      },
      family_health_history_label: {
        father: "Ayah",
        mother: "Ibu",
        siblings: "Saudara Kandung",
      },
      working_history_label: {
        noise_vibration: "Kebisingan",
        temperature_over: "Suhu Ekstrim",
        smoke_dust: "Asap atau debu",
        chemical: "Kimia",
        big_machine: "Alat Berat",
      },
      habits_label: {
        smoking: "Merokok",
        drunk: "Minuman Keras",
        drugs: "Narkoba",
      },
      head_label: {
        hair: "Rambut",
      },
      ears_label: {
        ear_shape: "Bentuk Telinga",
        tympani_membran: "Membran Tympany",
        meatus_acusticus: "Meatus Acusticus",
      },
      nose_label: {
        nose_shape: "Bentuk Hidung",
        smelling: "Penciuman",
        mucosa_nose: "Mukosa Hidung",
        epistaksis: "Epistaksis",
      },
      throat_label: {
        tonsil_faring: "Tonsil & Faring",
        laring: "Laring / Pita Suara",
      },
      mounth_teeth_label: {
        mukosa_mounth: "Mukosa Mulut",
        tongue: "Lidah",
        teeth: "Gigi",
      },
      neck_label: {
        lympe_thyroid: "Kelenjar Lympe dan Gondok",
        jagular_venous_pressure: "Jagular Venous Pressure (JVP)",
      },
      thorax_label: {
        thorax_shape: "Bentuk Thorax",
        thorax_moving: "Pergerakan",
        lungs: "Paru-paru",
        heart_thorax: "Jantung",
        backbone: "Tulang Belakang",
      },
      abdomen_label: {
        usus_wall: "Dinding Usus",
        genitalia: "Genitalia",
        liver_and_spleen: "Hati & Limpa",
      },
      extremitas_label: {
        extremitas_top: "Extremitas Atas",
        extremitas_bottom: "Extremitas Bawah",
      },
      reflex_label: {
        physioligy: "Physioligy",
        phatology: "Phatology",
      },
      coordination_label: {
        teremor: "Tremor",
        tonus: "Tonus",
        paresis: "Paresis",
        paralysis: "Paralysis",
      },
      // Options
      selectMethod: [
        { text: "Tidak", value: 0 },
        { text: "Ya", value: 1 },
      ],
      selectCondition: [
        { text: "Normal", value: 0 },
        { text: "Tidak Normal", value: 1 },
      ],
      // data
      notes: {
        health_history_notes: "",
        family_health_history_notes: "",
        physical_status_notes: "",
        working_history_notes: "",
        habits_notes: "",
        head_notes: "",
        eyes_notes: "",
        ears_notes: "",
        nose_notes: "",
        throat_notes: "",
        mounth_teeth_notes: "",
        neck_notes: "",
        thorax_notes: "",
        abdomen_notes: "",
        extremitas_notes: "",
        reflex_notes: "",
        coordination_notes: "",
      },
      // Others
      imgLoaded: false,
      uploadedImageUrl: "",
      // CK Editor
      editor: DecoupledEditor,
    };
  },

  computed: {
    formatedAmount: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.amount = newValue.toLocaleString("id-ID");
        } else {
          this.form.amount = "";
        }
      },
      get() {
        return this.form.amount;
      },
    },
  },

  methods: {
    cancle() {
       this.$router.push('/medical-checkup/list')
    },

    formaterDate(val) {
      if (val.length == 4) {
        this.form.mcu_time = this.form.mcu_time + "-";
      }
      if (val.length == 7) {
        this.form.mcu_time = this.form.mcu_time + "-";
      }
    },

    // CK Editor Initalization
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },

    setBmi() {
      if (
        this.form.weight != "" &&
        this.form.height != ""
      ) {
        let height = this.form.height / 100; //eheight in meters
        let bmi = this.form.weight / (height * height);

        if (bmi >= 18.5 && bmi <= 24.9) {
          this.form.bmi =
            "Index Masa Tubuh Ideal (" + Number(bmi).toFixed() + ")";
        } else if (bmi >= 25.0 && bmi <= 29.9) {
          this.form.bmi =
            "Kelebihan Berat Badan / Gemuk (" + Number(bmi).toFixed() + ")";
        } else if (bmi > 30.0) {
          this.form.bmi =
            "Obesitas (" + Number(bmi).toFixed() + ")";
        } else {
          this.form.bmi =
            "Berat Badan Kurang Baik / Kurus (" + Number(bmi).toFixed() + ")";
        }

        
      }
    },

    setPatient(value) {
      this.form.patient_id = value.id;
      this.form.patient_name = value.name;
      this.form.no_patient = value.number;
      this.display.patient_name = value.name;

      this.$bvModal.hide("modal-patient");
    },

    async getDoctorOption() {
      let response = await module.setDoctorTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
      }
    },

    async formOnSubmit() {
      // stringify data
      let dataForm = {};
      dataForm = { ...dataForm, ...this.form };

      dataForm.health_history = JSON.stringify(this.form.health_history);
      dataForm.family_health_history = JSON.stringify(
        this.form.family_health_history
      );
      dataForm.working_history = JSON.stringify(this.form.working_history);
      dataForm.habits = JSON.stringify(this.form.habits);
      dataForm.head = JSON.stringify(this.form.head);
      dataForm.eyes = JSON.stringify(this.form.eyes);
      dataForm.ears = JSON.stringify(this.form.ears);
      dataForm.nose = JSON.stringify(this.form.nose);
      dataForm.throat = JSON.stringify(this.form.throat);
      dataForm.mounth_teeth = JSON.stringify(this.form.mounth_teeth);
      dataForm.neck = JSON.stringify(this.form.neck);
      dataForm.thorax = JSON.stringify(this.form.thorax);
      dataForm.abdomen = JSON.stringify(this.form.abdomen);
      dataForm.extremitas = JSON.stringify(this.form.extremitas);
      dataForm.reflex = JSON.stringify(this.form.reflex);
      dataForm.coordination = JSON.stringify(this.form.coordination);

      dataForm = { ...dataForm, ...this.notes };


      let formData = new FormData();
      for (const [key, value] of Object.entries(dataForm)) {
        formData.append(key, value);
      }
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/medical-checkup/list");
      }
    },
  },

  watch: {
    "form.patient_name": function (newVal, oldVal) {
      this.display.patient_name = newVal;
    },
    "form.doctor_id": function (newVal, oldVal) {
    },
  },

  mounted() {
    // this.getMethod();
    this.getDoctorOption();
    if(this.type == "edit"){
      
        // set notes value
        this.notes.health_history_notes = this.form.health_history_notes
        this.notes.family_health_history_notes = this.form.family_health_history_notes
        this.notes.physical_status_notes = this.form.physical_status_notes
        this.notes.working_history_notes = this.form.working_history_notes
        this.notes.habits_notes = this.form.habits_notes
        this.notes.head_notes = this.form.head_notes
        this.notes.eyes_notes = this.form.eyes_notes
        this.notes.ears_notes = this.form.ears_notes
        this.notes.nose_notes = this.form.nose_notes
        this.notes.throat_notes = this.form.throat_notes
        this.notes.mounth_teeth_notes = this.form.mounth_teeth_notes
        this.notes.neck_notes = this.form.neck_notes
        this.notes.thorax_notes = this.form.thorax_notes
        this.notes.abdomen_notes = this.form.abdomen_notes
        this.notes.extremitas_notes = this.form.extremitas_notes
        this.notes.reflex_notes = this.form.reflex_notes
        this.notes.coordination_notes = this.form.coordination_notes
      
    }

  },
};
</script>

<style scoped>
.theme--light.v-application {
  background: none;
}

.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

.document-editor__editable-container {
  padding: calc(2 * var(--ck-spacing-large));
  background: var(--ck-color-base-foreground);

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.document-editor__editable-container .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 15.8cm;
  min-height: 21cm;

  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 0 auto;
}

.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc(
    1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
  );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor
  .ck-heading-dropdown
  .ck-list
  .ck-button:not(.ck-heading_paragraph)
  .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: normal;
}

.document-editor .ck-content h2 {
  line-height: 1.37em;
  padding-top: 0.342em;
  margin-bottom: 0.142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: normal;
  color: hsl(203, 100%, 50%);
}

.document-editor
  .ck-heading-dropdown
  .ck-heading_heading2.ck-on
  .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
  line-height: 1.86em;
  padding-top: 0.171em;
  margin-bottom: 0.357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: bold;
}

.document-editor .ck-content h4 {
  line-height: 1.24em;
  padding-top: 0.286em;
  margin-bottom: 0.952em;
}

/* Set the styles for "Paragraph". */
.document-editor .ck-content p {
  font-size: 1em;
  line-height: 1.63em;
  padding-top: 0.5em;
  margin-bottom: 1.13em;
}

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc(2 * var(--ck-spacing-large));
  margin-right: calc(2 * var(--ck-spacing-large));
}
</style>
